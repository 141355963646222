<template>
  <Article title="Cookie policy">
    <h2 class="shx-h2">What Are Cookies</h2>
    <p class="shx-paragraph">
      As is common practice with almost all professional websites this site uses
      cookies, which are tiny files that are downloaded to your computer, to
      improve your experience. This page describes what information they gather,
      how I use it and why I sometimes need to store these cookies. I will also
      share how you can prevent these cookies from being stored however this may
      downgrade or ‘break’ certain elements of the sites functionality.
    </p>
    <p class="shx-paragraph">
      For more general information on cookies see the Wikipedia article on HTTP
      Cookies.
    </p>

    <h2 class="shx-h2">How I use Cookies</h2>
    <p class="shx-paragraph">
      I use cookies for a variety of reasons detailed below. Unfortunately in
      most cases there are no industry standard options for disabling cookies
      without completely disabling the functionality and features they add to
      this site. It is recommended that you leave on all cookies if you are not
      sure whether you need them or not in case they are used to provide a
      service that you use.
    </p>

    <h2 class="shx-h2">Disabling Cookies</h2>
    <p class="shx-paragraph">
      You can prevent the setting of cookies by adjusting the settings on your
      browser (see your browser Help for how to do this). Be aware that
      disabling cookies will affect the functionality of this and many other
      websites that you visit. Disabling cookies will usually result in also
      disabling certain functionality and features of the this site. Therefore
      it is recommended that you do not disable cookies.
    </p>

    <h2 class="shx-h2">The Cookies I Set</h2>
    <ul>
      <li>
        <p class="shx-paragraph">
          Forms related cookies when you submit data to through a form such as
          those found on contact pages cookies may be set to remember your user
          details for future correspondence.
        </p>
      </li>
      <li>
        <p class="shx-paragraph">
          Cookies related to keeping track of consents or lack of them, as
          required by GDPR.
        </p>
      </li>
    </ul>

    <h2 class="shx-h2">Third Party Cookies</h2>
    <p class="shx-paragraph">
      In some special cases I also use cookies provided by trusted third
      parties. The following section details which third party cookies you might
      encounter through this site.
    </p>
    <ul>
      <li>
        <p class="shx-paragraph">
          This site uses Google Analytics which is one of the most widespread
          and trusted analytics solution on the web for helping us to understand
          how you use the site and ways that I can improve your experience.
          These cookies may track things such as how long you spend on the site
          and the pages that you visit so I can continue to produce engaging
          content.
        </p>
      </li>
    </ul>

    <h2 class="shx-h2">More Information</h2>
    <p class="shx-paragraph">
      However if you are looking for more information then you can
      <a href="/kontakt">contact me</a>
    </p>
  </Article>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
});
</script>

<style lang="scss" scoped>
ul {
  padding: 0;
  list-style: none;
}

ul li:before {
  content: "";
  width: 1rem;
  height: 0.2rem;
  background: var(--shx-orange);
  position: absolute;
  margin-left: -2rem;
  margin-top: 1rem;
}

* + h2 {
  margin-top: 4rem;
}
</style>
